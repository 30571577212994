.container {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #fcfcfc;
}
body,
html {
  font-family: "ABC Ginto Nord Unlicensed Trial", sans-serif;
  font-family: "ABC Ginto Nord Condensed Unlicensed Trial", sans-serif;
  font-family: "ABC Ginto Normal Unlicensed Trial", sans-serif;
}

@layer base {
  img {
    display: initial;
  }
}
